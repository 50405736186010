import { i18n } from '@/main';
import {
  TYPE_AI_FLOW,
  KEY_AI_FLOW,
  TYPE_AI_OBJECT,
  DEVICE_TYPE,
} from '@/util/common-constant';
export default {
  resetData(state) {
    state.listCameraScan = [];
    state.linkStream = null;
    state.idBox = null;
    state.listScanHistory = [];
    state.recordDevice = null;
    state.all = [];
    state.aiFlowDTOList = [];
    state.listPolygon = [];
    state.pointZone = [];
  },
  resetLine(state) {
    state.pointZone = [];
  },
  transformArray(state, arr) {
    const result = [];
    state.pointZone = [];
    for (let i = 0; i < arr.length; i += 2) {
      result.push(arr.slice(i, i + 2));
    }
    state.pointZone = result;
  },
  createNewFormListDevice(state, data) {
    const { t } = i18n.global;
    const humanAI = [
      {
        name: t('device.intrusion'),
        key: KEY_AI_FLOW.humanIntrusionCount,
        active: false,
        type: TYPE_AI_FLOW.HUMAN_INTRUSION,
      },
      {
        name: t('device.flow'),
        key: KEY_AI_FLOW.humanFlowCount,
        active: false,
        type: TYPE_AI_FLOW.HUMAN_FLOW,
      },
      {
        name: t('device.access_control'),
        key: KEY_AI_FLOW.humanAccessControlCount,
        active: false,
        type: TYPE_AI_FLOW.HUMAN_ACCESS_CONTROL,
      },
      {
        name: t('device.weapon_detect'),
        key: KEY_AI_FLOW.humanWeaponDetectCount,
        active: false,
        type: TYPE_AI_FLOW.HUMAN_WEAPON_DETECT,
      },
      {
        name: t('device.feature'),
        key: KEY_AI_FLOW.humanFeatureCount,
        active: false,
        type: TYPE_AI_FLOW.HUMAN_FEATURE,
      },
    ];
    const vehicleAI = [
      {
        name: t('device.intrusion'),
        key: KEY_AI_FLOW.vehicleIntrusionCount,
        active: false,
        type: TYPE_AI_FLOW.VEHICLE_INTRUSION,
      },
      {
        name: t('device.flow'),
        key: KEY_AI_FLOW.vehicleFlowCount,
        active: false,
        type: TYPE_AI_FLOW.VEHICLE_FLOW,
      },
      {
        name: t('device.access_control'),
        key: KEY_AI_FLOW.vehicleAccessControlCount,
        active: false,
        type: TYPE_AI_FLOW.VEHICLE_ACCESS_CONTROL,
      },
      {
        name: t('device.feature'),
        key: KEY_AI_FLOW.vehicleFeatureCount,
        active: false,
        type: TYPE_AI_FLOW.VEHICLE_FEATURE,
      },
    ];
    const dataDefault = [
      {
        id: 1,
        name: t('device.human'),
        icon: require('@/static/img/device/person.svg'),
        total: 0,
        Ai: humanAI,
        key: TYPE_AI_OBJECT.HUMAN,
      },
      {
        id: 2,
        name: t('device.vehicle'),
        icon: require('@/static/img/device/car.svg'),
        total: 0,
        Ai: vehicleAI,
        key: TYPE_AI_OBJECT.VEHICLE,
      },
    ];
    if (Array.isArray(data)) {
      const listDatanew = data.map((device) => {
        const listCamera = Array.isArray(device.cameras)
          ? device.cameras.map((camera) => {
              dataDefault.forEach((item) => {
                if (item.key === TYPE_AI_OBJECT.HUMAN) {
                  item.total = camera.humanCount || 0;
                  item.Ai.forEach((ai) => {
                    ai.active = camera[ai.key] > 0;
                    if (device.type === DEVICE_TYPE.BOX) {
                      ai.isDiff = camera.groupDiffList.includes(ai.type);
                    }
                  });
                } else {
                  item.total = camera.vehicleCount || 0;
                  item.Ai.forEach((ai) => {
                    ai.active = camera[ai.key] > 0;
                    if (device.type === DEVICE_TYPE.BOX) {
                      ai.isDiff = camera.groupDiffList.includes(ai.type);
                    }
                  });
                }
              });

              return {
                ...camera,
                listContentAi: JSON.parse(JSON.stringify(dataDefault)),
              };
            })
          : device.cameras;
        dataDefault.forEach((item) => {
          const isHuman = item.key === TYPE_AI_OBJECT.HUMAN;
          const totalKey = isHuman
            ? KEY_AI_FLOW.humanCount
            : KEY_AI_FLOW.vehicleCount;
          const groupAIs =
            device.type === DEVICE_TYPE.BOX ? device.groupAIs || [] : null;

          item.total = device[totalKey] || 0;
          item.Ai.forEach((ai) => {
            ai.active = groupAIs
              ? groupAIs.includes(ai.type)
              : device[ai.key] > 0;
          });
        });
        return {
          ...device,
          listContentAi: JSON.parse(JSON.stringify(dataDefault)),
          cameras: listCamera,
        };
      });
      state.listDevice = listDatanew;
    }
  },

  transformPoints(state, payload) {
    let arrayData = JSON.parse(payload.point);
    let line = JSON.parse(payload.line);
    const index = state.aiFlowPolygon.findIndex(
      (item) => item.id === payload.ids
    );
    if (!payload.isChecked) {
      state.aiFlowPolygon.splice(index, 1);
    } else {
      state.aiFlowPolygon.push({
        points: arrayData?.map(([x, y]) => [
          (x / 1920) * state.widthPolygon,
          (y / 1080) * state.heightPolygon,
        ]),
        id: payload.ids,
        text: payload?.name,
        stroke: 'blue',
        fontColor: 'blue',
        line:
          line && line.length > 0
            ? line.map(([x, y]) => [
                (x / 1920) * state.widthPolygon,
                (y / 1080) * state.heightPolygon,
              ])
            : undefined,
      });
    }
    state.points = arrayData.map(([x, y]) => [
      (x / 1920) * state.widthPolygon,
      (y / 1080) * state.heightPolygon,
    ]);
  },
  getAll(state, payload) {
    let arrayData = JSON.parse(payload.point);
    let line = JSON.parse(payload.line);
    payload.data.push({
      points: arrayData?.map(([x, y]) => [
        (x / 1920) * state.widthPolygon,
        (y / 1080) * state.heightPolygon,
      ]),
      id: payload.ids,
      name: payload?.name,
      stroke: 'blue',
      fontColor: 'blue',
      line:
        line && line.length > 0
          ? line.map(([x, y]) => [
              (x / 1920) * state.widthPolygon,
              (y / 1080) * state.heightPolygon,
            ])
          : undefined,
      active: payload.active,
      typeZone: payload.type,
    });
    state.points = arrayData.map(([x, y]) => [
      (x / 1920) * state.widthPolygon,
      (y / 1080) * state.heightPolygon,
    ]);
    state.all = payload.data;
  },
};
