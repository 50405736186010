import mutations from './mutations';
import { getField, updateField } from 'vuex-map-fields';
import { DataService } from '@/dataService/dataService';
import ConstantAPI from '@/config/ConstantAPI';
import { DEVICE_TYPE } from '@/util/common-constant';
import { showConfirm } from '@/util/confirm';
import { notification } from 'ant-design-vue';
import { i18n } from '@/main';
const state = () => ({
  api: ConstantAPI.DEVICE,
  listDevice: [],
  listCameraScan: [],
  linkStream: null,
  isShowSettingBox: false,
  recordBox: null,
  isShowSettingCamera: false,
  recordDevice: null,
  isShowSettingDevice: false,
  listScanIp: [],
  recordCamera: null,
  aiFlowDTOList: [],
  listScanHistory: [],
  pointZone: [],
  isShowCreateCamera: false,
  listCameraNotInGroup: [],
  recordCameraEdit: null,
  isShowEditCamera: false,
  recordStream: null,
  isShowStream: false,
  recordReStream: null,
  isShowReStream: false,
  widthPolygon: null,
  heightPolygon: null,
  aiFlowPolygon: [],
  listPolygon: [],
  checkedList: [],
  listZoneActive: [],
  points: [],
  all: [],
  isPolyComplete: false,
  dataFill: {
    typeZone: '',
    name: '',
  },
  nameHeaderDevice: null,
  idAiFlow: null,
  listDoor: [],
  indexLine: null,
});

const actions = {
  async getListDevice({ commit, state }) {
    try {
      const listData = [];
      const response = await DataService.callApi(
        state.api.GET_LIST_DEVICE,
        null,
        null
      );
      (response.groupTreeDTOList || []).map((item) => {
        if (item.type === DEVICE_TYPE.BOX && item.ip) {
          listData.push(item);
        }
        (item.childGroups || []).map((box) => {
          if (box.ip) {
            listData.push(box);
          }
        });
      });
      (response.doorDTOList || []).map((item) => {
        listData.push({
          ...item,
          type: DEVICE_TYPE.DOOR,
        });
      });
      (response.cameraDTOList || []).map((item) => {
        listData.push({
          ...item,
          type: DEVICE_TYPE.CAMERA,
        });
      });

      commit('createNewFormListDevice', listData);
    } catch (err) {
      console.log(err);
    }
  },
  async updateSelectAi({ state }, params) {
    const response = await DataService.callApi(
      state.api.UPDATE_AI_FLOW,
      params,
      null
    );
    return response;
  },
  async updateSelectAiCamera({ state }, params) {
    const response = await DataService.callApi(
      state.api.UPDATE_AI_FLOW_CAMERA,
      params,
      null
    );
    return response;
  },
  async getStreamCamera({ state }, params) {
    try {
      const response = await DataService.callApi(
        state.api.STREAM_CAMERA,
        null,
        params
      );
      state.linkStream = response;
    } catch (err) {
      console.log(err);
    }
  },
  handleShowSettingBox({ commit, state }, params) {
    const { value, record } = params;
    if (!value) {
      commit('resetData');
    }
    state.isShowSettingBox = value;
    state.recordBox = record;
  },
  handleShowEditCamera({ commit, state }, params) {
    const { value, record } = params;
    if (!value) {
      commit('resetData');
    }
    state.isShowEditCamera = value;
    state.recordCameraEdit = record;
  },
  handleShowStream({ state }, params) {
    const { value, record } = params;
    state.isShowStream = value;
    state.recordStream = record;
  },
  handleShowReStream({ state }, params) {
    const { value, record } = params;
    state.isShowReStream = value;
    state.recordReStream = record;
  },
  handleSendLinePoint({ commit }, params) {
    commit('transformArray', params);
  },
  handleShowSettingCamera({ commit, state }, params) {
    const { value, record } = params;
    if (!value) {
      commit('resetData');
    }
    state.isShowSettingCamera = value;
    state.recordCamera = record;
  },
  handleShowCreateCamera({ state }, value) {
    state.isShowCreateCamera = value;
  },
  handleShowSettingDevice({ commit, state }, params) {
    const { value, record, nameHeader } = params;
    if (!value) {
      commit('resetData');
    }
    state.nameHeaderDevice = nameHeader;
    state.isShowSettingDevice = value;
    state.recordDevice = record;
  },
  async createDiscoveryInfo({ state }, params) {
    await DataService.callApi(
      state.api.CREATE_DISCOVERY_IN_GROUPS,
      params,
      null
    );
  },
  async addCameraInBox({ state }, params) {
    const { t } = i18n.global;
    await DataService.callApi(state.api.ADD_CAM_IN_BOX, params, null);
    notification.success({
      message: t('common.notification'),
      description: t('common.create_success', {
        name: 'camera',
      }),
      duration: 4,
    });
  },
  async getCameraInGroup({ state }, params) {
    try {
      const response = await DataService.callApi(
        state.api.SEARCH_CAMERA_IN_AI_BOX,
        null,
        params
      );
      state.listCameraScan = response
        ? response.map((item) => ({
            ...item,
            checked: false,
          }))
        : [];
    } catch (err) {
      console.log(err);
    }
  },
  async createZone({ commit, state }, params) {
    const response = await DataService.callApi(
      state.api.CREATE_ZONE,
      params,
      null
    );
    commit('resetLine');
    return response;
  },

  async createAiFlows({ state }, params) {
    const response = await DataService.callApi(
      state.api.CREATE_AI_FLOW,
      params,
      null
    );
    return response;
  },
  async addZoneAiFlows({ state }, params) {
    const response = await DataService.callApi(
      state.api.ADD_ZONE_AI_FLOWS,
      params,
      null
    );
    return response;
  },
  async addAiInCamera({ state }, params) {
    const response = await DataService.callApi(
      state.api.ADD_AI_CAMERA,
      params,
      null
    );
    return response;
  },
  async getListAiFlows({ state }, params) {
    try {
      const response = await DataService.callApi(
        state.api.GET_LIST_AI_FLOWS,
        null,
        params
      );
      state.aiFlowDTOList = response ? response[0].aiFlowDTOList : [];
    } catch (err) {
      console.log(err);
    }
  },
  async removeAiFlows({ state }, params) {
    const { t } = i18n.global;
    if (await showConfirm(t('common.confirm_delete'))) {
      await DataService.callApi(state.api.REMOVE_AI_FLOWS, params, null);
      notification.success({
        message: t('common.notification'),
        description: t('common.delete_success', {
          name: 'camera',
        }),
        duration: 4,
      });
    }
  },
  async createDiscoveryOutGroup({ state }, params) {
    const response = await DataService.callApi(
      state.api.CREATE_DISCOVERY_OUT_GROUP,
      params,
      null
    );
    return response;
  },
  async scanCameraOutGroup({ state }) {
    const response = await DataService.callApi(
      state.api.SCAN_CAMERA_OUT_GROUP,
      null,
      null
    );
    return response;
  },
  async getListCameraNotInGroup({ state }) {
    try {
      const response = await DataService.callApi(
        state.api.GET_CAMERA_NOT_IN_GROUP,
        null,
        null
      );
      state.listCameraNotInGroup = response ? response : [];
    } catch (err) {
      console.log(err);
    }
  },
  async updateCamera({ state }, params) {
    const { t } = i18n.global;
    await DataService.callApi(state.api.EDIT_CAMERA, params, null);
    notification.success({
      message: t('common.notification'),
      description: t('common.update_success', {
        name: 'camera',
      }),
      duration: 4,
    });
  },
  async removeCameraNotInGroup({ state }, params) {
    const { t } = i18n.global;
    if (await showConfirm(t('common.confirm_delete'))) {
      await DataService.callApi(
        state.api.DELETE_CAMERA_NOT_IN_GROUP,
        params,
        null
      );
      notification.success({
        message: t('common.notification'),
        description: t('common.delete_success', {
          name: 'camera',
        }),
        duration: 4,
      });
    }
  },
  async createDiscoveryCameraOutGroupSingle({ state }, params) {
    const response = await DataService.callApi(
      state.api.SCAN_CAMERA_OUT_GROUP_SINGLE,
      params,
      null
    );
    return response;
  },
  async updateAiBox({ state }, params) {
    await DataService.callApi(state.api.UPDATE_AI_BOX, params, null);
  },
  async removeCamraInAibox({ state }, params) {
    await DataService.callApi(state.api.REMOVE_CAMERA_IN_AI_BOX, params, null);
  },
  async handleDeletePolygon({ state }, params) {
    const { t } = i18n.global;
    if (await showConfirm(t('common.confirm_delete'))) {
      await DataService.callApi(state.api.DELETE_POLYGON, null, params);
      notification.success({
        message: t('common.notification'),
        description: t('common.delete_success', {
          name: t('device.area'),
        }),
        duration: 4,
      });
    }
  },

  getWidthPolygon({ state }, params) {
    state.widthPolygon = params.width;
    state.heightPolygon = params.height;
  },
  handleUpdatePolygon({ state }, params) {
    try {
      DataService.callApi(state.api.EDIT_POLYGON, params, null);
      state.checkedList = [];
    } catch (err) {
      console.log(err);
    }
  },
  handleResetPolygon({ state }) {
    state.isPolyComplete = false;
    state.points = [];
  },
  async handleGetAllAiFlows({ state, commit }, params) {
    try {
      const response = await DataService.callApi(
        state.api.GET_AI_FLOWS,
        null,
        params
      );
      if (response) {
        state.listPolygon = params.ids ? response[0].polygonDTOs : [];
        let data = [];
        let getRandomRainbowColor = () => {
          const rainbowColors = [
            '#FF0000',
            '#FFFF00',
            '#008000',
            '#0000FF',
            '#4B0082',
            '#800080',
          ];
          const randomIndex = Math.floor(Math.random() * rainbowColors.length);
          return rainbowColors[randomIndex];
        };
        state.listPolygon.map((item, index) => {
          commit('getAll', {
            point: item.polygon,
            ids: item.id,
            line: item.line,
            name: item.name,
            active: item.active,
            type: item.type,
            data: data,
            color: getRandomRainbowColor(index),
          });
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  async createDeviceDoor({ state }, params) {
    const { t } = i18n.global;
    if (await showConfirm(t('common.confirm_save'))) {
      await DataService.callApi(state.api.CREATE_DEVICE_DOOR, params, null);
      notification.success({
        message: t('common.notification'),
        description: t('common.create_success', {
          name: t('menu_manager.device'),
        }),
        duration: 4,
      });
    }
  },
  async updateDeviceDoor({ state }, params) {
    const { t } = i18n.global;
    if (await showConfirm(t('common.confirm_save'))) {
      await DataService.callApi(state.api.UPDATE_DEVICE_DOOR, params, null);
      notification.success({
        message: t('common.notification'),
        description: t('common.update_success', {
          name: t('menu_manager.device'),
        }),
        duration: 4,
      });
    }
  },
  async deleteDeviceDoor({ state }, params) {
    const { t } = i18n.global;
    if (await showConfirm(t('common.confirm_delete'))) {
      await DataService.callApi(state.api.DELETE_DEVICE_DOOR, null, params);
      notification.success({
        message: t('common.notification'),
        description: t('common.delete_success', {
          name: t('menu_manager.device'),
        }),
        duration: 4,
      });
    }
  },
  async addPolygonDoor({ state }, params) {
    await DataService.callApi(state.api.ADD_POLYGON_DOOR, params, null);
  },
  async getAllDoor({ state }) {
    const response = await DataService.callApi(
      state.api.GET_ALL_DOOR,
      null,
      null
    );
    let door = [];
    response.map((item) => {
      door.push({
        value: item.id,
        label: item.doorType,
        info: item,
      });
    });
    state.listDoor = door;
  },
  async switchCamera({ state }, params) {
    const response = await DataService.callApi(
      state.api.DELETE_CAMERA_NOT_IN_GROUP,
      params,
      null
    );
    return response;
  },
  async getIdAiFlow({ state }, params) {
    const response = await DataService.callApi(
      state.api.GET_ID_AI_FLOW,
      null,
      params
    );
    state.idAiFlow = response ? response.id : null;
  },
  editPolygon({ state }, params) {
    let subArray = params.line;
    let mainArray = params.points;
    const indices = subArray.map((sub) =>
      mainArray.findIndex(
        (main) => JSON.stringify(main) === JSON.stringify(sub)
      )
    );
    state.indexLine = indices[0];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
